.DatePicker {
    composes: card from global;
}

.DatePickerHeader {
    gap: 1em;
}

.NudgeSelectors {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-self: center;
    min-width: 13em;
    row-gap: 0.5em;
}

.NudgeSelector {
    display: contents;
}


.nudgeLeft, .nudgeRight {
    font-size: 0.8em;
    display: block;
    /* border: none; */
    border-radius: var(--border-radius-small);
    width: 3em;
}



.monthTable {
    font-size: 0.8em;
}

.monthTable th {
    font-weight: 500;
}

.monthTable td {
    border: none;
}

.weekNames {
    text-transform: uppercase;
}

.allDays {
    border-radius: var(--border-radius-small);
    height: 3em;
    width: 3em;
}

.inMonth {
    background-color: var(--selectable-color);
}

.outMonth {
    opacity: 50%;
}

.selected {
    background-color: var(--selected-color);
    color: white;
    font-weight: normal;
}


.datePickerTransitionMarker {
    *:has(>.DatePicker) {
        view-transition-name: lookup-show-hide;
    }
}

@keyframes show {
    from {
        opacity: 0;
        scale: 0.5;
        left: -15%;
        top: -10%;
        /* translate: 0 0; */
    }
}

@keyframes hide {
    to {
        opacity: 0;
        scale: 0.5;
        left: -15%;
        top: -10%;
        /* translate: 0 0; */
    }
}

::view-transition-new(lookup-show-hide) {
    animation: show 0.25s;
    left: 0;
}
::view-transition-old(lookup-show-hide) {
    animation: hide 0.25s;
    left: 0;
}