.FormattedPrice {
    display: inline-block;
    transition: all 300ms;
    filter: blur(0);
    opacity: 1;
    transform: scale(1);
}

.FormattedPrice > span:not(.Frequency) {
    font-weight: 700;
}

.FormattedPrice > span:first-child {
    font-size: 2.5em;
}

.FormattedPrice > span:nth-child(2) {
    font-size: 1.5em;
}
