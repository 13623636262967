:root {
    --quotes-card-border-radius: 10px;
}

.Quotes {
    font-size: 0.9em;
    border-collapse: separate;
    border-spacing: 0 8px;
    width: 100%;
}

.Header > th {
    color: #00000066;
    font-weight: normal;
    text-align: left;
}

.Header > :first-child, .QuoteRow > :first-child {
    border-top-left-radius: var(--quotes-card-border-radius);
    border-bottom-left-radius: var(--quotes-card-border-radius);
}

.Header > :last-child, .QuoteRow > :last-child {
    border-top-right-radius: var(--quotes-card-border-radius);
    border-bottom-right-radius: var(--quotes-card-border-radius);
}

/* every cell in our header and content grids */
.Header > th, .QuoteRow > td {
    padding: 1.2em;
    align-items: center;
    align-content: center;
    background-color: var(--card-background-color);
}

.QuoteRow:hover > * {
    cursor: pointer;
    background-color: var(--sovereign-blue-light);
}