.IamPanel {
    composes: card from global;
    width: 20em;
    opacity: 1;
    transition: all 0.2s;

    view-transition-name: IamPanel;
    
    h3 {
        view-transition-name: IamHeading;
    }
    a {
        align-self: flex-end;
        &:last-child {
            view-transition-name: IamLink;
        }
    }

    input[type=email] {
        view-transition-name: IamEmail;
    }

    &[data-busy="true"] {
        /* opacity: 0.5; */
        pointer-events: none;
    }

    button {
        view-transition-name: IamButton;
    }
}

::view-transition-old(IamPanel),
::view-transition-new(IamPanel) {
    height: 100%;
    mix-blend-mode: normal;
    overflow: clip;
}

[data-modal-pane]:has(.IamPanel) {
    background-color: rgb(0, 0, 0, 0.5);
}


.spinner {
    background-color: rgb(256, 0, 0, 0.2);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

