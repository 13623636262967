@layer components {

    .lookupInput {
        font-family: inherit;
    }

    .lookupList {
        background-color: white;
        border: 1px solid #bbb;
        border-radius: 0.2em;
        width: fit-content;
        margin: 0;
        position: relative;
        transform: translate(-50%, 0);
        left: 50%;
        padding-inline: 2em 1em;

        li {
            cursor: pointer;
            padding-inline: 0;
            list-style: none;

            &[data-highlighted] {
                list-style: circle;
            }

            &[data-selected] {
                list-style: disc;
            }
        }

        &::before {
            position: absolute;
            content: '';
            display: block;
            height: 1em;
            width: 1em;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%) rotate(135deg);
            clip-path: polygon(0 0, 100% 100%, 0 100%);
            border: inherit;
            background-color: inherit;
        }

    }
}

