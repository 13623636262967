.Overview {
    >h1 {
        margin-right: auto;
    }
}

.Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2em;
    margin: 4em auto;
    width: max-content;
}

.Cards {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.Cards > * {
    min-width: 20em;
}

.Plans {
    max-width: 1200px;
    min-width: 960px;
}