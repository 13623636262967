.eap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.eap .description {
    font-size: 0.8em;
    color: black;   
}

.TextOnly {
    padding: 0.4em;
}