

.PlanMemberCards {
    display: flex;
    /* Note: this has to be the same as card-gap property in PlanConfiguration component */
    column-gap: 2em; 
}

.PlanMemberCard {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
}

.Button {
    cursor: pointer;
}

.ButtonDisabled {
    pointer-events: none;
    visibility: hidden;
}

.LifeCount {
    text-align: center;
    border: solid white;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }
}