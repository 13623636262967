body :has(.Welcome) {
    background-color: var(--sovereign-blue);
    color: white;
    padding: 0;
    height: var(--app-height);
}

.Welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    row-gap: 1em;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Header {
    font-size: 68px;
}

.Text {
    display: flex;
    flex-direction: column;
    row-gap: 3em;
}

.Navigation {
    display: flex;
    flex-direction: row;
    column-gap: 1em;
    margin-top: 3em;

    & .Button {
        border-color: var(--sovereign-blue-contrast);
        background-color: var(--sovereign-blue-contrast);
        color: white;
    }
}