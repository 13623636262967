@layer components {

    .DatePickerHeader {
        display: flex;
        flex-direction: column;
    }

    .nudgeSelector {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }


    .nudgeLeft::before {
        content: '<';
    }

    .nudgeSelectorText {
        cursor: pointer;
    }


    .nudgeRight::before {
        content: '>';
    }
}