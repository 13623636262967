@layer components {

    .DatePickerMonthTable {
        table-layout: fixed;
        text-align: center;
    }

    .DatePickerMonthTable th {
        text-align: center;
        padding: 0.5em;
    }

    .DatePickerMonthTable td {
        border: 1px solid lightgray;
        text-align: center;
        vertical-align: middle;
    }


    .allDays {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 2em;
        cursor: pointer;
    }

    .outMonth {
        opacity: 50%;
    }

    .inMonth {}

    .disabled {
        text-decoration: line-through;
        opacity: 25%;
        cursor: not-allowed;
    }

    .selected {
        font-weight: bold;
    }

    .today {}

    .weekend {}

    .weekday {}

}