/* TODO: sort out as a lot are similar to PlanBuilder */
:root {
    --plan-background-color: var(--card-background-color);
    --plan-active-background-color: var(--highlighted-color);
    --plan-border-color: var(--selected-color);
    --plan-title-color: var(--sovereign-goto-turquoise);
    --benefits-label-background-color: var(--sovereign-blue-light);

    --plan-active-border: 2px solid var(--plan-border-color);

    --card-gap: 2em;
    --card-height: 55px;
}

.PlanViewer {
    color: var(--sovereign-blue-dark); 
}

:global(.PlanConfigurationCard) {
    background-color: var(--plan-background-color);
    flex-basis: 8em;
    flex-grow: 1;
}

.PlanViewer .planConfiguration {
    & h4 {
        color: var(--plan-title-color);
    }

    /* benefits label card */
    & > :first-child {
        background-color: var(--benefits-label-background-color);
        border: none !important;
    }

    & :global(.PlanConfigurationCard) {
        border: 2px dashed var(--plan-border-color);

        &.PlanCardActive {
            border: var(--plan-active-border);
            background-color: var(--plan-active-background-color);
        }
    }
}

.EmployeeCardContainer {
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: var(--card-gap);
    margin-top: var(--card-gap);
    align-items: center;
}

.EmployeeCardContainer .PlanInfoContainer {
    display: flex;
    flex-direction: column;
    row-gap: var(--card-gap);
}

.EmployeeCardContainer .PlanLabelsContainer {
    display: flex;
    flex-direction: column;
    row-gap: var(--card-gap);
}

.EmployeeCardContainer .PlanMembership {
    & :global(.PlanConfigurationCard) {
        & > * {
            height: var(--card-height);
        }

        &.PlanCardActive {
            border: var(--plan-active-border);
        }
    }
}

/* TODO: we really need to standardise these somewhere as cards */
.Label {
    height: var(--card-height);
    border-radius: 10px;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}