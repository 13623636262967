.label {
    display: grid;
    grid-template-columns: 2fr 10fr 1fr;
    align-items: center;
}

.icon {
    text-align: left;
}

.text {
    text-align: left;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
}

.name {
    font-weight: bold;
}

.description {
    font-size: 0.8em;
}

.description b {
    color: var(--sovereign-goto-turquoise);
}

.info {
    text-align: right;
}