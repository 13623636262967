:root {
    --quotes-card-border-radius: 10px;
}

.ListTable {
    font-size: 0.9em;
    border-collapse: separate;
    border-spacing: 0 8px;
    width: 100%;
}

.Header>th {
    color: #00000066;
    font-weight: normal;
    text-align: left;
}

.Header> :first-child,
.ListTable tbody>tr :first-child {
    border-top-left-radius: var(--quotes-card-border-radius);
    border-bottom-left-radius: var(--quotes-card-border-radius);
}

.Header> :last-child,
.ListTable tbody>tr :last-child {
    border-top-right-radius: var(--quotes-card-border-radius);
    border-bottom-right-radius: var(--quotes-card-border-radius);
}

.Header>th,
.ListTable td {
    padding: 1.2em;
    align-items: center;
    align-content: center;
    background-color: var(--card-background-color);
}