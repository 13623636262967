.Quote {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Section {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}