.Toggle {
    display: inline-flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
}

.Toggle > input {
    position: relative;
    appearance: none;
    height: 1em;
    width: 1.5em;
    border-radius: 2em;
    transition: 200ms all;
    cursor: pointer;
    box-sizing: content-box;
}

.Toggle > input::before {
    content: ' ';
    top: 50%;
    left: 2%;
    transform: translate(0, -51%);
    position: absolute;
    width: 50%;
    padding-top: 50%;
    border-radius: 1em;
    transition: 200ms all;
}

.Toggle > input:checked::before {
    left: 47%;
}