.Summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Summary h1,
.Summary p,
.Summary table {
    text-align: left;
}

.Summary p,
.Summary table {
    font-size: 0.9em;
}

.Summary b,
.Summary h4 {
    color: var(--sovereign-blue-dark);
}

.ButtonContainer {
    display: flex;
    justify-content: center;
    gap: 1em;
}

.Premiums {
    align-items: center;
    border-radius: 20px;
    display: flex;
    padding: 0.5em 6em;

    background-color: var(--sovereign-goto-turquoise);
    color: white;
}

.Section {
    width: 100vw;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 2em 4em;
}

.Section .Contents { 
    display: flex;
    flex-direction: column;
    gap: 1em;

    &:is(:not(.Wide)) {
        width: 568px;
    }
}
