.Card {
    text-align: center;
    border-radius: 10px;
}

/* allow overrides */
:global(.PlanConfigurationCard) {
    & > * {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
