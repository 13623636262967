body :has(.Welcome) {
    color: black;
    padding: 0;
    height: var(--app-height);
}

.Success {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    row-gap: 1em;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Header {
    font-size: 32px;
}

.Text {
    display: flex;
    flex-direction: column;
    row-gap: 3em;
}
