.modal {
    background-color: white;
    border-radius: 0.5em;
    position: relative;
    max-height: 70vh;
    overflow-y: scroll;
    border: 1px solid var(--border-color);
    padding: 2em;
}

[data-modal-pane]:has(.modal) {
    background-color: #00000080;
}

.Content {
    text-align: center;
    min-width: 600px;
    min-height: 300px;
}

.Message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;

    & p {
        padding: 0.5em 0;
    }
}
