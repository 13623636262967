

@font-face {
    font-family: brandon-grotesque;
    font-weight: 100;
    src: url("./Brandon_Grotesque_thin.otf") format("opentype");
}

@font-face {
    font-family: brandon-grotesque;
    font-weight: 300;
    src: url("./Brandon_Grotesque_light.otf") format("opentype");
}

@font-face {
    font-family: brandon-grotesque;
    font-weight: 400;
    src: url("./Brandon_Grotesque_regular.otf") format("opentype");
}

@font-face {
    font-family: brandon-grotesque;
    font-weight: 500;
    src: url("./Brandon_Grotesque_medium.otf") format("opentype");
}

@font-face {
    font-family: brandon-grotesque;
    font-weight: 700;
    src: url("./Brandon_Grotesque_bold.otf") format("opentype");
} 

@font-face {
    font-family: brandon-grotesque;
    font-weight: 900;
    src: url("./Brandon_Grotesque_black.otf") format("opentype");
} 

