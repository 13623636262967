.Footer {
    position: fixed;
    bottom: 0;
    color: white;

    width: 100vw;
    max-height: 90px;
    height: 90px;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.Footer label {
    font-size: 2rem;
    text-transform: uppercase;
    margin-right: 10px;
    font-weight: bold;
}

.Footer > div {
    padding: 0 4em 0 2em;
}

.Footer .CurrentPrice {
    background-color: var(--header-background-color);
    display: flex;
    align-items: center;
}

.Footer .NewPrice {
    background-color: var(--footer-background-color);
    align-items: center;

    display: flex;
    justify-content: space-between;
    padding-right: 5em;
}

.Footer .NewPrice button {
    padding-left: 2em;
    padding-right: 2em;
}