.Footer {
    composes: padding-standard from global;
    background-color: var(--footer-background-color);
    color: var(--footer-text-color);
    min-height: 6em;

    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
}
