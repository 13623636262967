

.lookupList {
    overflow-y: scroll;
    max-height: 10em;
    /* max-width: 20em; */
    li {
        /* white-space: unset; */
    }
    &::before {
        display: none;
    }
}

.lookupInput {
    
}



