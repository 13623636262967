.spinner {
    -webkit-animation: peachy-spinner__spin 1s linear infinite;
    animation: peachy-spinner__spin 1s linear infinite;
}

@-webkit-keyframes peachy-spinner__spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes peachy-spinner__spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}