.ContactDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 3em;
    margin-top: 3em;
    width: 35em;
}

.MainContact {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.Info {
    font-style: italic;
}

.Buttons {
    display: flex;
    justify-content: center;
    column-gap: 1em;
}