:root {
    --page-scroll-top: 0;
    --header-height: clamp(4em, 8em, calc(8em - var(--page-scroll-top) * 0.02em));
    --app-height: 100vh;
}


body {
    min-height: var(--app-height);
    & > header {
        z-index: 1;
    }
}

[data-modal-pane] {
    z-index: 1;
}

