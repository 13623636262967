:root {
    --plan-background-color: var(--card-background-color);
    --plan-active-background-color: var(--highlighted-color);
    --plan-border-color: var(--selected-color);
    --plan-title-color: var(--sovereign-goto-turquoise);
    --benefits-label-background-color: var(--sovereign-blue-light);

    --plan-active-border: 2px solid var(--plan-border-color);

    --card-gap: 2em;
}

.planBuilder {
    color: var(--sovereign-blue-dark); 
}

:global(.PlanConfigurationCard) {
    background-color: var(--plan-background-color);
    flex-basis: 8em;
    flex-grow: 1;
}

.planBuilder .planConfiguration {
    & h4 {
        color: var(--plan-title-color);
    }

    /* benefits label card */
    & > :first-child {
        background-color: var(--benefits-label-background-color);
        border: none !important;
    }

    & :global(.PlanConfigurationCard) {
        border: 2px dashed var(--plan-border-color);

        &.planCardActive {
            border: var(--plan-active-border);
            background-color: var(--plan-active-background-color);
        }
    }
}

.employeeCardContainer {
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: var(--card-gap);
    margin-top: var(--card-gap);
    align-items: center;
}

.employeeCardContainer .planInfoContainer {
    display: flex;
    flex-direction: column;
    row-gap: var(--card-gap);
}

.employeeCardContainer .planActionsContainer {
    display: flex;
    flex-direction: column;
    row-gap: var(--card-gap);
}

.employeeCardContainer .planInfoContainer .planMembership {
    & > :first-child {
        visibility: hidden;
    }

    & :global(.PlanConfigurationCard) {
        & > * {
            height: 3em;
        }

        &.planCardActive {
            border: var(--plan-active-border);
        }
    }
}