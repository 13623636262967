@layer components {

    .Header {
        background-color: var(--header-background-color);
        position: sticky;
        height: var(--header-height);
        top: 0;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        justify-content: center;
        align-items: center;
        z-index: 2;

        nav {
            * {
                color: white;
            }

            justify-self: stretch;
            display: flex;
            padding-inline: 2em;
            gap: 1em;

            &:first-child {
                justify-content: start;
            }

            &:last-child {
                justify-content: end;
            }

            i {
                font-size: 2em;
            }

            a:global(.active) {
                &::after {
                    content: '';
                    display: block;
                    height: 2px;
                    width: 100%;
                    background-color: white;
                }
            }
        }
    }

    :global(.routeTransitionLink) {
        .Header a:global(.active)::after {
            view-transition-name: header-indicator;
        }
    }

    .Logo {
        width: calc(var(--header-height) * 1.5);
        aspect-ratio: 3/1;
        padding-block-start: 0.5em;
        grid-column: 2;
        fill: white;
    }

}