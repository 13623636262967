.Members {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2em;

    >h1 {
        margin-right: auto;
    }
}

.Buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.EditMemberContainer {
    display: flex;
    column-gap: 1em;
}