.table {
    text-align: center;
    cursor: default;
}

.table :where(th, td) {
    padding: 0.5rem;
}

/* TODO shouldn’t be dependent on the sovereign colours. (Same with buttons) */
.errorCell {
    background-color: var(--sovereign-blue-light);
}