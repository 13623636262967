body :has(.StripeySections) {
    background-color: var(--body-alt-background-color);
    padding: 0;
    height: var(--app-height);
}

.StripeySections {
    & .Section:nth-child(odd) {
        background-color: var(--body-alt-background-color) !important;
    }

    & .Section:nth-child(even) {
        background-color: var(--body-background-color) !important;
    }
}

.Section {
    width: 100vw;
    margin: 0 auto;
    padding: 4em;
    display: flex;
    justify-content: center;
}

.Section .Text {
    text-align: center;

    & p {
        font-size: 1.2em;
    }
}

.Section .Form {
    align-items: left;

    & input[type="radio"] {
        margin-right: 1em;
    }
}

.Section .Contents { 
    display: flex;
    flex-direction: column;
    gap: 3em;

    &:is(:not(.Wide)) {
        width: 568px;
    }
}