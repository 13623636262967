@layer components {
    .ModalPane {
        position: fixed;
        inset: 0;
        height: 100dvh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
}