.CompanyCard {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    min-width: 15em;
    background-color: var(--card-background-color); 
    border-radius: 1em;
    padding: 1em;
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.1)) drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.1));
}

.Title {
    color: var(--sovereign-blue-dark);
    font-weight: bold;
}

.Item {
    font-size: 0.9em;

    & b {
        font-weight: bold;
        color: var(--sovereign-goto-turquoise);
    }
}