.SummaryChanges {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
}

.SummaryChangesTable {
    border-collapse: collapse;
    width: 100%;
}

.SummaryChangesTable :where(th, tr, td) {
    border: 1px solid black;
}

.SummaryChangesTable .Header>th {
    color: white;
    background-color: var(--sovereign-blue-dark);
}

.SummaryChangesTable :where(th, td) {
    padding: 1em;
}