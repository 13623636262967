.modal {
    /* TODO-MN use variable for bg-colour */
    background-color: white;
    border-radius: 0.5em;
    position: relative;
    max-height: 70vh;
    overflow-y: scroll;
    border: 1px solid var(--border-color);
}

.modal section {
    padding: 2em 2em 1em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1em;
    overflow-y: scroll;
}

.modal h3, p {
    cursor: default;
}

.footerContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1em;
}

.footerContainer button {
    padding-left: 2em;
    padding-right: 2em;
}


.footerContainer .bottomLeft  {
    display: flex;
    column-gap: 1em;
}
