.Modal {
    cursor: wait;
    pointer-events: all;
    z-index: 100;
    background: #FFFFFFBB;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Pulse {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3em;
}

.Dot {
    border-radius: 50%;
    animation: pulse 400ms ease-out infinite alternate;
    height: 80px;
    width: 80px;
}

/* if we have a smaller screen size, make the dots smaller */
@media screen and (max-width: 900px) {
    .Dot {
        height: 50px;
        width: 50px;
    }
}

.Dot:nth-child(2) {
    animation-delay: 125ms;
}

.Dot:nth-child(3) {
    animation-delay: 250ms;
}



@keyframes pulse {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* this is copied from peachy-library.module.css */
clickable:hover {
    cursor: pointer;
}
