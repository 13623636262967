@import "./fonts/brandon-grotesque/brandon-grotesque.css";

:root {

    --sovereign-blue: #2f91d0;
    --sovereign-blue-pale: #F1F8FF;
    --sovereign-blue-light: #E0EBF7;
    --sovereign-blue-dark: #186EA2;
    --sovereign-blue-contrast: #72B3DD;
   

    --sovereign-goto-turquoise-pale: #4DBFBA;
    --sovereign-goto-turquoise-light: #C4E4DF;
    --sovereign-goto-turquoise: #00A39C;

    --sovereign-green-pale: #def0e2;
    --sovereign-green: #5fb671;
    --sovereign-green-dark: #3a7f48;

    --sovereign-purple-pale: #ece2ee;
    --sovereign-purple: #9c75aa;
    --sovereign-purple-light: #9d75ab;
    --sovereign-purple-dark: #6a4976;

    --blue-grey: #B0B8C7;
}


:root {
    --header-background-color: var(--sovereign-blue);
    --body-background-color: var(--sovereign-blue-pale);
    --body-alt-background-color: white;

    --footer-background-color: var(--sovereign-goto-turquoise);
    --footer-text-color: white;
    --footer-button-color: var(--sovereign-goto-turquoise-light);

    --page-colour: var(--sovereign-blue-pale);
    --main-font: brandon-grotesque, Helvetica, Arial, sans-serif;

    --primary-color: var(--sovereign-goto-turquoise);
    --primary-color-light: var(--sovereign-goto-turquoise-light);


    --highlighted-color: var(--sovereign-goto-turquoise-light);    
    --selected-color: var(--sovereign-goto-turquoise);
    --invalid-field-color: var(--sovereign-blue-pale);
    --error-message-color: var( --sovereign-goto-turquoise);

    --selectable-color: var(--sovereign-blue-light);


    --border-color: var(--blue-grey);
    
    --standard-border: 2px solid var(--border-color);

    --border-radius-small: 0.25em;
    --border-radius-medium: 0.5em;
    --border-radius-large: 1em;

    --padding-small: 0.25em;
    --padding-medium: 0.5em;
    --padding-large: 1em;
    --padding-xlarge: 2em;

    --card-background-color: white;
    --transition-time: 0.2s;
}


body {
    font-family: var(--main-font);
    background-color: var(--body-background-color);
}

*:focus {
    outline-color: var(--sovereign-blue);
    outline-width: 1px;
}

input {
    border: var(--standard-border);
    border-radius: var(--border-radius-small);
    padding: 0.25em;
}

/* toggles */
input[type="checkbox"] {
    border-color: var(--blue-grey);
    background-color: var(--blue-grey);
    min-width: 2em;
    height: 1em;

    &:checked {
        border-color: var(--selected-color);
        background-color: var(--selected-color);
    }

    &::before {
        background-color: white;
    }
}


input[type="radio"] {
    transform: scale(1.3);
}

input[type="radio"], input[type="checkbox"], select {
    cursor: pointer;
}

input:not([type="checkbox"], [type="radio"]), select {
    padding: 0.5em;
    border-radius: var(--border-radius-medium);
    height: 3em;
}

label :has(input[type="checkbox"]) {
    display: flex;
}
 
h1 {
    font-size: 1.8em;
}

h2 {
    color: var(--sovereign-goto-turquoise);
}

h3 {
    color: var(--sovereign-blue-dark); 
}

button {
    transition: all var(--transition-time), scale 0.1s;
    border: var(--standard-border);
    border-color: var(--primary-color);
    color: var(--primary-color);
    background-color: var(--primary-color-light);
    padding: 0.25em;
    border-radius: var(--border-radius-small);
    cursor: pointer;
    
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
    
    &:hover:not(:disabled), &:focus:not(:disabled) {
        background-color: var(--primary-color);
        color: white;
    }
    
    &:active {
        scale: 1.05
    }

    scale: 1;
}


a {
    cursor: pointer;
    text-decoration: none;
}

[data-modal-pane] {
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.1)) drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1));
}
