.Form {
    display: grid;
    gap: 1em;
    grid-template-areas:
        "account-name account-name"
        "sort-code account-number"
}

.AccountName {
    grid-area: account-name;
}

.SortCode {
    grid-area: sort-code;
}

.AccountNumber {
    grid-area: account-number;
}

.DirectDebitLabel {
    display: flex;
    justify-content: space-between;
    height: 50px;
}

.DirectDebitLogo {
    width: 140px;
    margin-top: -45px;
}

.BillingAddress {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CollectionDate {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 1em;
}