

@layer overrides {


    .lookupShowHideTransition {
        *:has(>.lookupList) {
            view-transition-name: lookup-show-hide;
        }
    }
    
    .lookupUpdateTransition {
        *:has(>.lookupList) {
            view-transition-name: lookup-update;
            ul::before {
                view-transition-name: lookup-update-tag;
            }
        }
    }
    
    @keyframes show {
        from {
            scale: 0;
            opacity: 0;
            translate: 0 -50%;
        }
    }
    
    @keyframes hide {
        to {
            scale: 0;
            opacity: 0;
            translate: 0 -50%;
        }
    }
    
    ::view-transition-old(lookup-update),
    ::view-transition-new(lookup-update) {
        height: 100%;
        mix-blend-mode: normal;
        overflow: clip;
    }
    
    ::view-transition-new(lookup-show-hide) {
        animation: show 0.25s;
    }
    ::view-transition-old(lookup-show-hide) {
        animation: hide 0.25s;
    }
    
    
    .lookupList {
        margin-top: var(--padding-medium);
        border: 1px solid var(--primary-color);
        background-color: white;
        padding-inline: 0;
        li {
            list-style: none;
            white-space: nowrap;
            padding-inline: 0.5em;
            &[data-highlighted] {
                outline: inherit;
                outline-width: 1px;
                background-color: var(--highlighted-color);
            }
            &[data-selected] {
                background-color: var(--selected-color);
                color: white;
            }
        }
    }
    
    .lookupInput {
        min-width: 10em;
        position: relative;
    }

    .spinner {
        position: absolute;
        right: 1.5em
    }
}


