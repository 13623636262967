.QuoteBuilder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 3em;
    max-width: 1200px;
    min-width: 960px;
}

.DoubleGrid {
    display: grid; 
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.StartDate {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    & input {
        width: max-content;
    }
}

.CompanyDetails {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    padding-bottom: 10rem;
}

.DownloadPDF {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}