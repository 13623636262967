.Button {
    /* should be a solid button */
    text-align: center;
    border: 2px solid;
    border-radius: 0.5em;
    font-weight: bold;
    transition: all 200ms;
    font-size: 1em;
    min-width: 160px;
    min-height: 50px;

    &:disabled {
        background-color: transparent;
    }
    
    &:not(:disabled):hover {
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05), 1px 2px 2px rgba(0, 0, 0, 0.05);
        transform: scale(1.05);
    }
}

.Outline {
    background-color: transparent;
}

.Primary {
    background-color: var(--sovereign-blue);
    border-color: var(--sovereign-blue);
    color: white;

    &:disabled {
        color: var(--sovereign-blue);
    }
}

.Secondary {
    border-color: var(--sovereign-blue);
    color: var(--sovereign-blue);
}

.Grey {
    background-color: var(--sovereign-blue-light);
    border-color: var(--sovereign-blue-light);
    color: var(--sovereign-blue);
}

.Green {
    border-color: var(--sovereign-goto-turquoise-light);
    background-color: var(--sovereign-goto-turquoise-light);
    color: var(--sovereign-goto-turquoise);
}

.OutlineGreen {
    border-color: var(--sovereign-goto-turquoise);
    color: var(--sovereign-goto-turquoise);
}

.QuoteFooter {
    background-color: var(--sovereign-goto-turquoise-pale);
    border-color: var(--sovereign-goto-turquoise-pale);
    color: white;
}
